import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';

export class Slider {
  constructor() {

    new Swiper('.slider-simple', {
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      autoHeight: true,
    })

    new Swiper('.slider-custom', {
      modules: [Pagination, Autoplay],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      slidesPerView: 1,
      loop: true,
      spaceBetween: 20,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        1300: {
          slidesPerView: 5,
        },
        800: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 3,
        },
        400: {
          slidesPerView: 2,
        },
      },
    })

    new Swiper('.slider-double', {
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 10,
      breakpoints: {
        500: {
          slidesPerView: 2,
          centeredSlides: false,
        },
      },
    })

    new Swiper('.slider-actu', {
      modules: [Pagination, Navigation],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
      loop: true,
      spaceBetween: 38,
      breakpoints: {
        1000: {
          slidesPerView: 3,
        },
        600: {
          slidesPerView: 2,

        },
      },
    })
  }
}
