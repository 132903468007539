export class ScrollHeader {
  constructor() {
    const header = document.querySelector('.banner')

    if (header) {
      window.addEventListener('scroll', function () {
        setTimeout(() => {
          if (window.scrollY > 80) {
            header.classList.add('active')
          }
          if (window.scrollY < 80 && header.classList.contains('active')) {
            header.classList.remove('active')
          }
        }, 100)
      })
    }
  }
}
