export class Accordeon {
  constructor() {
    const accHeading = document.querySelectorAll('.accordeon_header')
    const accPanel = document.querySelectorAll('.accordeon_panel')

    if (accHeading) {
      for (let i = 0; i < accHeading.length; i++) {
        accHeading[i].addEventListener('click', function (e) {
          console.log(e.currentTarget)
          if (this.nextElementSibling.style.maxHeight) {
            for (let i = 0; i < accPanel.length; i++) {
              accPanel[i].style.maxHeight = null;
              accHeading[i].classList.remove('active');
            }
          } else {
            for (let i = 0; i < accPanel.length; i++) {
              accPanel[i].style.maxHeight = null;
              accHeading[i].classList.remove('active');
            }
            this.classList.add('active');
            let that = this
            this.nextElementSibling.style.maxHeight = this.nextElementSibling.scrollHeight + 'px';

            setTimeout(function () {
              that.scrollIntoView()
            }, 400)
          }
        })
      }
    }
  }
}
