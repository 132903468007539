import {Header} from '../util/header';
import {Slider} from '../util/slider';
import {Accordeon} from '../util/Accordeon';
import {ScrollHeader} from '../util/ScrollHeader';
import AOS from 'aos';



export default {
  init() {
    new Header()
    new Slider()
    new Accordeon()
    new ScrollHeader()
    AOS.init();
    AOS.refresh();

  },
  finalize() {
  },
};
